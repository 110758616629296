import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { ServiceCard } from '../Cards'
import { theme } from '../../styles'

function ServicesGrid({ services }) {
  services.sort((a, b) => a.data.order - b.data.order)

  return (
    <ServiceContainer>
      {services.map((s) => (
        <ServiceCard
          key={s.data.service_name.text}
          title={s.data.service_name.text}
          imageFluid={s.data.service_image.localFile.childImageSharp.fluid}
          description={s.data.description.text}
        />
      ))}
    </ServiceContainer>
  )
}

const ServiceContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  justify-items: center;
  column-gap: 10px;
  row-gap: 10px;

  @media (max-width: ${theme.breakpoints.s}) {
    grid-template-columns: auto;
  }
`

ServicesGrid.propTypes = {
  services: PropTypes.array,
}

ServicesGrid.defaultProps = {
  services: [],
}

export default ServicesGrid
