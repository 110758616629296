import React from 'react'
import { graphql, navigate } from 'gatsby'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import BackgroundImage from 'gatsby-background-image'
import { Header, Footer } from '../components/Common'
import { PageSection } from '../components/Layout'
import theme from '../styles/theme'
import { Button } from '../components/Controls'
import { ServicesGrid } from '../components/Home'

const HomePage = ({ data }) => {
  const pageData = data.allPrismicHomePage.edges[0].node.data
  const services = data.allPrismicService.nodes
  const commonData = data.allPrismicCommonPageContent.nodes[0].data

  return (
    <div>
      <Header logoUrl={commonData.logo_image.url} />
      <BackgroundImage
        Tag="section"
        fluid={pageData.hero_image.localFile.childImageSharp.fluid}
        backgroundColor="#000000"
      >
        <HeroContainer
          dangerouslySetInnerHTML={{ __html: pageData.hero_text.html }}
        />
      </BackgroundImage>
      <PageSection>
        <IntroductionContainer>
          <IntroductionMessage
            dangerouslySetInnerHTML={{ __html: pageData.introduction.html }}
          />
          <Button handleClick={() => navigate('/contact')}>Contact Us</Button>
        </IntroductionContainer>
      </PageSection>
      <PageSection color="grey">
        <ServicesTitle>Services</ServicesTitle>
        <ServicesGrid services={services} />
      </PageSection>
      <Footer data={commonData.footer_text_content.html} />
    </div>
  )
}

const HeroContainer = styled.div`
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${theme.colors.white};
  padding: 0 20px;

  & h1,
  h2,
  h3,
  p {
    color: ${theme.colors.white};
  }

  @media (max-width: ${theme.breakpoints.s}) {
    height: 350px;

    & h1,
    h2,
    h3,
    p {
      font-size: 34px;
    }
  }
`

const IntroductionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const IntroductionMessage = styled.div`
  text-align: center;
  font-size: 22px;
  margin-bottom: 20px;

  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 18px;
  }
`

const ServicesTitle = styled.h2`
  margin-bottom: 60px;

  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 18px;
  }
`

export const query = graphql`
  query IndexPageQuery {
    allPrismicCommonPageContent {
      nodes {
        data {
          footer_text_content {
            html
          }
          logo_image {
            url
          }
        }
      }
    }
    allPrismicService {
      nodes {
        data {
          order
          description {
            text
          }
          service_image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 300, maxHeight: 200, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          service_name {
            text
          }
        }
      }
    }
    allPrismicHomePage {
      edges {
        node {
          data {
            hero_text {
              html
            }
            hero_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            introduction {
              html
            }
          }
        }
      }
    }
  }
`

// allPrismicHomePage {
//   edges {
//     node {
//       data {
//         footer_text_content {
//           html
//         }
//       }
//     }
//   }
// }

HomePage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default HomePage
