import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import theme from '../../styles/theme'

const Button = ({ handleClick, children }) => {
  return <ButtonElemenet onClick={handleClick}>{children}</ButtonElemenet>
}

const ButtonElemenet = styled.button`
  padding: 12px 20px;
  color: ${theme.colors.white};
  background-color: ${theme.colors.warning};
  border: 1px solid ${theme.colors.warningDark};
  font-weight: 600;
  font-size: 20px;
  display: inline-block;
  max-width: 160px;

  &:hover {
    background-color: ${theme.colors.warningDark};
    cursor: pointer;
  }
`

Button.propTypes = {
  children: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
}

Button.defaultProps = {
  children: '',
}

export default Button
