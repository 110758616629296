import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import { navigate } from 'gatsby'
import { theme } from '../../styles'

function ServiceCard({ title, imageFluid, description, key }) {
  return (
    <Card key={key} onClick={() => navigate('/about')}>
      <Image fluid={imageFluid} alt={title} />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Card>
  )
}

const Image = styled(Img)`
  width: 100% !important;

  img {
    width: 100% !important;
    height: auto !important;
  }

  picture {
    width: 100% !important;
    height: auto !important;
  }
`

const Card = styled.div`
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 340px;
  width: 100%;

  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    margin-top: -3px;
    margin-bottom: 3px;
    cursor: pointer;
  }
`

const Title = styled.h3`
  margin-top: 40px;

  @media (max-width: ${theme.breakpoints.s}) {
    h2 {
      font-size: 30px;
    }
  }
`

const Description = styled.p`
  @media (max-width: ${theme.breakpoints.s}) {
    font-size: 18px;
  }
`

ServiceCard.propTypes = {
  title: PropTypes.string.isRequired,
  imageFluid: PropTypes.object.isRequired,
  description: PropTypes.string,
}

ServiceCard.defaultProps = {
  description: '',
}

export default ServiceCard
